import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { GlobalState } from "../../../store";
import * as ApplicationSaga from '../../../store/sagas/application';
import ApplicationEdit from "./Edit";
import "ag-grid-community/styles/ag-grid.css";
import "@proag/sprout/themes/ag-theme-sprout.css";
import caseInsensitiveComparator from "../../helpers/caseInsensitiveComparator";
import { AgGridReact } from 'ag-grid-react';
import { GridApi } from 'ag-grid-community';
import { GridReadyEvent, ICellRendererParams } from 'ag-grid-community';

interface ModalState {
    show: boolean;
    application?: ApplicationSaga.Application;
}

const ApplicationList = () => {
    const dispatch = useDispatch();
    const [modalState, setModalState] = useState<ModalState>({ show: false });
    const [gridApi, setGridApi] = useState<GridApi>();

    const applicationState = useSelector<GlobalState, ApplicationSaga.ApplicationState>((state) => state.applicationState);

    useEffect(() => { dispatch(ApplicationSaga.getApplications()) }, []);

    const handleModalClose = () => {
        setModalState({ ...modalState, show: false });
    }

    const deleteApplication = (application: ApplicationSaga.Application) => {
        dispatch(ApplicationSaga.deleteApplication(application.id));
    }

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    }

    const exportData = () => {
        if (gridApi) {
            gridApi.exportDataAsCsv({
                processHeaderCallback: (params) => {
                    if (params.column.getColId() === 'actions') {
                        return '';
                    }
                    return params.column.getColDef().headerName ?? '';
                },
                fileName: "applications.csv"
            });
        }
    }

    return (
        <>
            <div className="float-end mt-3">
                <Button variant="outline-secondary" size="sm" className="me-2" onClick={exportData}>Export Data</Button>
                <Button variant="accent1" onClick={() => setModalState({ show: true })}>Create Application</Button>
            </div>
            <h1>Applications</h1>
            <div
                className="ag-theme-sprout"
                style={{ height: 600 }}
            >
                <AgGridReact
                    alwaysShowHorizontalScroll={false}
                    alwaysShowVerticalScroll={true}
                    animateRows={true}
                    columnDefs={[
                        { field: "id", headerName: "App Id", maxWidth: 80 },
                        { field: "name", headerName: "Name", sortable: true, comparator: caseInsensitiveComparator, filter: true, wrapText: true, maxWidth: 250 },
                        { field: "description", headerName: "Description", wrapText: true },
                        {
                            field: "actions",
                            headerName: "Actions",
                            cellRenderer: (params: ICellRendererParams) => {
                                return <>
                                    <Button size="sm" className="me-2" onClick={() => setModalState({ show: true, application: params.data })}>
                                        Edit
                                    </Button>

                                    <Button size="sm" onClick={() => confirm("Deleting this Application will remove all feature flags and other settings. Continue?") && deleteApplication(params.data)}>
                                        Delete
                                    </Button>
                                </>
                            },
                            maxWidth: 140
                        }
                    ]}
                    defaultColDef={{
                        cellStyle: () => ({
                            display: "flex",
                            alignItems: "center",
                        }),
                    }}
                    onGridReady={onGridReady}
                    rowData={applicationState.applications}
                    rowHeight={40}
                    suppressCellFocus={true}
                />
            </div>

            <Modal show={modalState.show} backdrop="static">
                <Modal.Body>
                    <ApplicationEdit application={modalState.application} handleClose={handleModalClose} />
                </Modal.Body>
            </Modal>
        </>
    );
};

export default ApplicationList;